import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, Detail, VisualizationAttr } from 'commons/components'

import deleteExpense from '../services/deleteExpense.js'

const DetailExpense = ({ objectDetailExpense }) => {
  const { checkPermission } = useAuth()
  const navigate = useNavigate()

  const hapus = async () => {
    await deleteExpense({
      id: objectDetailExpense.id,
    })
    navigate('/expense')
  }

  return (
    <Detail>
      {/* Data Binding Expense Data */}
      <VisualizationAttr
        label="Tanggal"
        content={objectDetailExpense?.datestamp}
      />
      <VisualizationAttr
        label="Deskripsi"
        content={objectDetailExpense?.description}
      />
      <VisualizationAttr
        label="Jumlah"
        content={objectDetailExpense?.amount}
        isCurrency
      />
      <VisualizationAttr
        label="Nama Program"
        content={objectDetailExpense?.programName}
      />
      <VisualizationAttr
        label="Jenis Pengeluaran"
        content={objectDetailExpense?.coaName}
      />
      <div class="card-actions justify-end">
        {/* View Element Event Expense Element*/}
        {checkPermission('DeleteExpense') && (
          <Button onClick={hapus} variant="tertiary">
            Hapus
          </Button>
        )}
      </div>
    </Detail>
  )
}

export default DetailExpense
