import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DetailIncome from '../components/DetailIncome'
import getIncomeDetail from '../services/getIncomeDetail'

const DetailIncomePage = props => {
  const [income, setIncome] = useState()
  const { id } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const { data: incomeDetail } = await getIncomeDetail({ id })
      setIncome(incomeDetail.data)
    }
    fetchData()
  }, [])

  return income ? (
    <div className="prose max-w-screen-lg mx-auto p-6">
      <h2>Detail Income</h2>
      <DetailIncome {...{ income }} />
    </div>
  ) : (
    <></>
  )
}

export default DetailIncomePage
