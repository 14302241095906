import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DetailProgram from '../components/DetailProgram'
import getProgramData from '../services/getProgramData'

const DetailProgramPage = props => {
  const [objectDetailProgram, setObjectDetailProgram] = useState()
  const { id } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const { data: programData } = await getProgramData({ id })
      setObjectDetailProgram(programData.data)
    }
    fetchData()
  }, [])

  return objectDetailProgram ? (
    <div className="prose max-w-screen-lg mx-auto p-6">
      <h2>Detail Program</h2>
      <DetailProgram {...{ objectDetailProgram }} />
    </div>
  ) : (
    <></>
  )
}

export default DetailProgramPage
