import useAppearance from 'commons/appearance/useAppearance'
import React, { forwardRef } from 'react'
import { INPUT_CLASSNAMES } from './variants'

const FileInputField = forwardRef(function FileInputField(props, ref) {
  const { label, className = '', kit } = props
  const interfaceKit = useAppearance()
  const inputStyle = (kit ?? interfaceKit).input
  const inputVariant = INPUT_CLASSNAMES[inputStyle]

  return (
    <div className="form-control">
      {label && <label className="label label-text">{label}</label>}
      <input
        className={`file-input w-full ${inputVariant} ${className}`}
        ref={ref}
        type="file"
        {...props}
      />
    </div>
  )
})

export default FileInputField
