import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import getConfirmOfflineDonation from '../services/getConfirmOfflineDonation'
import getStatuses from '../services/getStatuses'

import FormUpdateStatusKonfirmasiDonasiOffline from '../components/FormUpdateStatusKonfirmasiDonasiOffline'

const UpdateStatusKonfirmasiDonasiOfflinePage = props => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const [confirmOfflineDonation, setConfirmOfflineDonation] = useState()
  const [statuses, setStatuses] = useState()

  useEffect(() => {
    const fetch = async () => {
      const {
        data: confirmOfflineDonationResponse,
      } = await getConfirmOfflineDonation({ id })
      const { data: statusesResponse } = await getStatuses()

      setConfirmOfflineDonation(confirmOfflineDonationResponse.data)
      setStatuses(statusesResponse.data)
    }
    fetch()
  }, [])

  return confirmOfflineDonation && statuses ? (
    <FormUpdateStatusKonfirmasiDonasiOffline
      {...{ confirmOfflineDonation, statuses }}
      {...props}
    />
  ) : (
    <></>
  )
}

export default UpdateStatusKonfirmasiDonasiOfflinePage
