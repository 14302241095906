import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import getExpense from '../services/getExpense'
import getPrograms from '../services/getPrograms'
import getChartOfAccounts from '../services/getChartOfAccounts'

import FormUbahPengeluaran from '../components/FormUbahPengeluaran'

const UbahPengeluaranPage = props => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const [expense, setExpense] = useState()
  const [programs, setPrograms] = useState()
  const [chartOfAccounts, setChartOfAccounts] = useState()

  useEffect(() => {
    const fetch = async () => {
      const { data: expenseResponse } = await getExpense({ id })
      const { data: programsResponse } = await getPrograms()
      const { data: chartOfAccountsResponse } = await getChartOfAccounts()

      setExpense(expenseResponse.data)
      setPrograms(programsResponse.data)
      setChartOfAccounts(chartOfAccountsResponse.data)
    }
    fetch()
  }, [])

  return expense && programs && chartOfAccounts ? (
    <FormUbahPengeluaran
      {...{ expense, programs, chartOfAccounts }}
      {...props}
    />
  ) : (
    <></>
  )
}

export default UbahPengeluaranPage
