import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, Detail, VisualizationAttr } from 'commons/components'

const DetailKonfirmasiDonasiOffline = ({
  objectDetailKonfirmasiDonasiOffline,
}) => {
  const { checkPermission } = useAuth()
  const navigate = useNavigate()

  const updateStatus = async () => {
    navigate(
      '/confirmation/update?' + `id=${objectDetailKonfirmasiDonasiOffline.id}`
    )
  }

  return (
    <Detail>
      {/* Data Binding Konfirmasi Data */}
      <VisualizationAttr
        label="Nama"
        content={objectDetailKonfirmasiDonasiOffline?.name}
      />
      <VisualizationAttr
        label="Email"
        content={objectDetailKonfirmasiDonasiOffline?.email}
      />
      <VisualizationAttr
        label="No. Telp"
        content={objectDetailKonfirmasiDonasiOffline?.phone}
      />
      <VisualizationAttr
        label="Tanggal"
        content={objectDetailKonfirmasiDonasiOffline?.date}
      />
      <VisualizationAttr
        label="Jumlah"
        content={objectDetailKonfirmasiDonasiOffline?.amount}
      />
      <VisualizationAttr
        label="Deskripsi"
        content={objectDetailKonfirmasiDonasiOffline?.description}
      />
      <VisualizationAttr
        label="Metode Pembayaran"
        content={objectDetailKonfirmasiDonasiOffline?.paymentMethod}
      />
      <VisualizationAttr
        label="Status"
        content={objectDetailKonfirmasiDonasiOffline?.status}
      />
      <VisualizationAttr
        label="Bukti Transfer"
        content={objectDetailKonfirmasiDonasiOffline?.proofOfTransfer}
      />
      <div class="card-actions justify-end">
        {/* View Element Event Konfirmasi Element*/}
        {checkPermission('UpdateCOD') && (
          <Button onClick={updateStatus} variant="secondary">
            Update Status
          </Button>
        )}
      </div>
    </Detail>
  )
}

export default DetailKonfirmasiDonasiOffline
