import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import getRole from '../services/getRole'

import FormUbahRole from '../components/FormUbahRole'

const UbahRolePage = props => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const [role, setRole] = useState()

  useEffect(() => {
    const fetch = async () => {
      const { data: roleResponse } = await getRole({ id })

      setRole(roleResponse.data)
    }
    fetch()
  }, [])

  return role ? <FormUbahRole {...{ role }} {...props} /> : <></>
}

export default UbahRolePage
