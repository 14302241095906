import RequireAuth from 'commons/auth/RequireAuth'

import StaticPageDetail from './containers/StaticPageDetail'
import StaticPageEdit from './containers/StaticPageEdit'

const defaultPageRoutes = [
  { path: '/about', element: <StaticPageDetail slug="about" /> },
  { path: '/contact', element: <StaticPageDetail slug="contact" /> },
  { path: '/partners', element: <StaticPageDetail slug="partners" /> },
  { path: '/maps', element: <StaticPageDetail slug="maps" /> },
  {
    path: '/bank-account',
    element: <StaticPageDetail slug="bank-account" />,
  },
]

const path = '/static-page'

const staticPageRoutes = [
  ...defaultPageRoutes,
  { path: path + '/detail/:staticPageId', element: <StaticPageDetail /> },
  {
    path: path + '/edit/:staticPageId',
    element: (
      <RequireAuth permissionNeeded="administrator">
        <StaticPageEdit />
      </RequireAuth>
    ),
  },
]

export default staticPageRoutes
