import RequireAuth from 'commons/auth/RequireAuth'

import CatatanPengeluaranPage from './containers/CatatanPengeluaranPage'
import TambahPengeluaranPage from './containers/TambahPengeluaranPage'
import DetailPengeluaranPage from './containers/DetailPengeluaranPage'
import UbahPengeluaranPage from './containers/UbahPengeluaranPage'

const expenseRoutes = [
  {
    path: '/expense/tambah',
    element: (
      <RequireAuth permissionNeeded="CreateExpense">
        <TambahPengeluaranPage />
      </RequireAuth>
    ),
  },
  {
    path: '/expense/ubah',
    element: (
      <RequireAuth permissionNeeded="UpdateExpense">
        <UbahPengeluaranPage />
      </RequireAuth>
    ),
  },
  {
    path: '/expense/:id',
    element: <DetailPengeluaranPage />,
  },
  {
    path: '/expense',
    element: <CatatanPengeluaranPage />,
  },
]

export default expenseRoutes
