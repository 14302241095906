import RequireAuth from 'commons/auth/RequireAuth'

import KonfirmasiDonasiOfflinePage from './containers/KonfirmasiDonasiOfflinePage'
import BerhasilKonfirmasiPage from './containers/BerhasilKonfirmasiPage'
import ListKonfirmasiDonasiOfflinePage from './containers/ListKonfirmasiDonasiOfflinePage'
import DetailKonfirmasiDonasiOfflinePage from './containers/DetailKonfirmasiDonasiOfflinePage'
import UpdateStatusKonfirmasiDonasiOfflinePage from './containers/UpdateStatusKonfirmasiDonasiOfflinePage'

const confirmationRoutes = [
  {
    path: '/confirmation',
    element: <ListKonfirmasiDonasiOfflinePage />,
  },
  {
    path: '/confirmation/update',
    element: (
      <RequireAuth permissionNeeded="UpdateCOD">
        <UpdateStatusKonfirmasiDonasiOfflinePage />
      </RequireAuth>
    ),
  },
  {
    path: '/confirmation/:id',
    element: <BerhasilKonfirmasiPage />,
  },
  {
    path: '/confirmation/detail/:id',
    element: <DetailKonfirmasiDonasiOfflinePage />,
  },
  {
    path: '/confirmation/confirm',
    element: <KonfirmasiDonasiOfflinePage />,
  },
]

export default confirmationRoutes
