import React, { useEffect, useState } from 'react'

import getPrograms from '../services/getPrograms'
import getChartOfAccounts from '../services/getChartOfAccounts'

import FormTambahkanPemasukan from '../components/FormTambahkanPemasukan'

const TambahIncomePage = props => {
  const [programs, setPrograms] = useState()
  const [chartOfAccounts, setChartOfAccounts] = useState()

  useEffect(() => {
    const fetch = async () => {
      const { data: programsResponse } = await getPrograms()
      const { data: chartOfAccountsResponse } = await getChartOfAccounts()

      setPrograms(programsResponse.data)
      setChartOfAccounts(chartOfAccountsResponse.data)
    }
    fetch()
  }, [])

  return programs && chartOfAccounts ? (
    <FormTambahkanPemasukan {...{ programs, chartOfAccounts }} {...props} />
  ) : (
    <></>
  )
}

export default TambahIncomePage
