import React from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, ListItem, VisualizationAttr } from 'commons/components'

const ProgramCard = ({ programItem }) => {
  const { checkPermission } = useAuth()

  return (
    <ListItem>
      {/* Data Binding Program Card Element */}
      <div className="card-body">
        <VisualizationAttr label="Gambar" content={programItem?.logoUrl} />
        <VisualizationAttr label="Nama" content={programItem?.name} />
        <div className="card-actions justify-end">
          {/* View Element Event Program Card Element*/}
          <Link to={`/activity/${programItem.id}`}>
            <Button variant="tertiary">Detail</Button>
          </Link>
        </div>
      </div>
    </ListItem>
  )
}

export default ProgramCard
