import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  List,
} from 'commons/components'

import UserTable from '../components/UserTable'
import getUserListElement from '../services/getUserListElement'

const DaftarUserPage = props => {
  const { checkPermission } = useAuth()
  const [user, setUser] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const { data: userListElement } = await getUserListElement()
      setUser(userListElement.data)
    }
    fetchData()
  }, [])

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0">Daftar User</h2>
        <div className="not-prose flex items-center gap-4">
          <Link to="/settings/user/tambah">
            <Button>Tambah User</Button>
          </Link>
        </div>
      </div>
      <div className="not-prose">
        <TableView {...{ user }} />
      </div>
    </div>
  )
}

const TableView = ({ user }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell id="nama">Nama</TableCell>
              <TableCell id="email">Email</TableCell>
              <TableCell id="allowedPermissions">AllowedPermissions</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user &&
              user.map(userItem => (
                <UserTable key={userItem.id} userItem={userItem} />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default DaftarUserPage
