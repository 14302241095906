import React, { useEffect, useState } from 'react'

import getPrograms from '../services/getPrograms'

import FormKonfirmasiDonasiOffline from '../components/FormKonfirmasiDonasiOffline'

const KonfirmasiDonasiOfflinePage = props => {
  const [programs, setPrograms] = useState()

  useEffect(() => {
    const fetch = async () => {
      const { data: programsResponse } = await getPrograms({})

      setPrograms(programsResponse.data)
    }
    fetch()
  }, [])

  return programs ? (
    <FormKonfirmasiDonasiOffline {...{ programs }} {...props} />
  ) : (
    <></>
  )
}

export default KonfirmasiDonasiOfflinePage
