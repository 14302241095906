import RequireAuth from 'commons/auth/RequireAuth'

import DaftarProgramPage from './containers/DaftarProgramPage'
import TambahProgramPage from './containers/TambahProgramPage'
import DetailProgramPage from './containers/DetailProgramPage'
import UbahProgramPage from './containers/UbahProgramPage'

const activityRoutes = [
  {
    path: '/activity/tambah',
    element: (
      <RequireAuth permissionNeeded="CreateProgram">
        <TambahProgramPage />
      </RequireAuth>
    ),
  },
  {
    path: '/activity/ubah',
    element: (
      <RequireAuth permissionNeeded="UpdateProgram">
        <UbahProgramPage />
      </RequireAuth>
    ),
  },
  {
    path: '/activity/:id',
    element: <DetailProgramPage />,
  },
  {
    path: '/activity',
    element: <DaftarProgramPage />,
  },
]

export default activityRoutes
