import React, { useEffect, useState } from 'react'

import getRoles from '../services/getRoles'

import FormTambahkanUser from '../components/FormTambahkanUser'

const TambahUserPage = props => {
  const [roles, setRoles] = useState()

  useEffect(() => {
    const fetch = async () => {
      const { data: rolesResponse } = await getRoles({})

      setRoles(rolesResponse.data)
    }
    fetch()
  }, [])

  return roles ? <FormTambahkanUser {...{ roles }} {...props} /> : <></>
}

export default TambahUserPage
