import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, Detail, VisualizationAttr } from 'commons/components'

import deleteProgram from '../services/deleteProgram.js'

const DetailProgram = ({ objectDetailProgram }) => {
  const { checkPermission } = useAuth()
  const navigate = useNavigate()

  const hapus = async () => {
    await deleteProgram({
      id: objectDetailProgram.id,
    })
    navigate('/activity')
  }

  const ubah = async () => {
    navigate('/activity/ubah?' + `id=${objectDetailProgram.id}`)
  }

  return (
    <Detail>
      {/* Data Binding Program Data */}
      <VisualizationAttr
        label="URL Gambar Program"
        content={objectDetailProgram?.logoUrl}
      />
      <VisualizationAttr label="Nama" content={objectDetailProgram?.name} />
      <VisualizationAttr
        label="Deskripsi"
        content={objectDetailProgram?.description}
      />
      <VisualizationAttr label="Target" content={objectDetailProgram?.target} />
      <VisualizationAttr
        label="Partner"
        content={objectDetailProgram?.partner}
      />
      <VisualizationAttr
        label="Tanggal Pelaksanaan"
        content={objectDetailProgram?.executionDate}
      />
      <div class="card-actions justify-end">
        {/* View Element Event Program Element*/}
        {checkPermission('DeleteProgram') && (
          <Button onClick={hapus} variant="tertiary">
            Hapus
          </Button>
        )}
        {checkPermission('UpdateProgram') && (
          <Button onClick={ubah} variant="secondary">
            Ubah
          </Button>
        )}
      </div>
    </Detail>
  )
}

export default DetailProgram
