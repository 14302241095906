import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import getUser from '../services/getUser'

import FormUbahUser from '../components/FormUbahUser'

const UbahUserPage = props => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const [user, setUser] = useState()

  useEffect(() => {
    const fetch = async () => {
      const { data: userResponse } = await getUser({ id })

      setUser(userResponse.data)
    }
    fetch()
  }, [])

  return user ? <FormUbahUser {...{ user }} {...props} /> : <></>
}

export default UbahUserPage
