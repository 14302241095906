import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import getUser from '../services/getUser'
import getRoles from '../services/getRoles'

import FormUbahRoleUser from '../components/FormUbahRoleUser'

const UbahRoleUserPage = props => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const [user, setUser] = useState()
  const [roles, setRoles] = useState()

  useEffect(() => {
    const fetch = async () => {
      const { data: userResponse } = await getUser({ id })
      const { data: rolesResponse } = await getRoles()

      setUser(userResponse.data)
      setRoles(rolesResponse.data)
    }
    fetch()
  }, [])

  return user && roles ? (
    <FormUbahRoleUser {...{ user, roles }} {...props} />
  ) : (
    <></>
  )
}

export default UbahRoleUserPage
