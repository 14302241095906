import React from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, TableRow, TableCell } from 'commons/components'

const RoleTable = ({ roleItem }) => {
  const { checkPermission } = useAuth()

  return (
    <TableRow distinct={false}>
      {/* Data Binding Role Table Element*/}
      <TableCell>{roleItem?.name}</TableCell>
      <TableCell>{roleItem?.allowedPermissions}</TableCell>
      <TableCell>
        <div class="btn-group gap-2">
          {/* View Element Event Role Table Element*/}
          <Link to={`/settings/role/${roleItem.id}`}>
            <Button variant="tertiary">Detail</Button>
          </Link>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default RoleTable
