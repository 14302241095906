import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  List,
} from 'commons/components'

import RoleTable from '../components/RoleTable'
import getRoleListElement from '../services/getRoleListElement'

const DaftarRolePage = props => {
  const { checkPermission } = useAuth()
  const [role, setRole] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const { data: roleListElement } = await getRoleListElement()
      setRole(roleListElement.data)
    }
    fetchData()
  }, [])

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0">Daftar Role</h2>
        <div className="not-prose flex items-center gap-4">
          <Link to="/settings/role/tambah">
            <Button>Tambah Role</Button>
          </Link>
        </div>
      </div>
      <div className="not-prose">
        <TableView {...{ role }} />
      </div>
    </div>
  )
}

const TableView = ({ role }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell id="nama">Nama</TableCell>
              <TableCell id="allowedPermissions">AllowedPermissions</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {role &&
              role.map(roleItem => (
                <RoleTable key={roleItem.id} roleItem={roleItem} />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default DaftarRolePage
