import React from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, TableRow, TableCell } from 'commons/components'

const DonasiTable = ({ donasiItem }) => {
  const { checkPermission } = useAuth()

  return (
    <TableRow distinct={false}>
      {/* Data Binding Donasi Table Element*/}
      <TableCell>{donasiItem?.name}</TableCell>
      <TableCell>{donasiItem?.email}</TableCell>
      <TableCell>{donasiItem?.phone}</TableCell>
      <TableCell>{donasiItem?.paymentMethod}</TableCell>
      <TableCell isCurrency>{donasiItem?.amount}</TableCell>
      <TableCell>{donasiItem?.status}</TableCell>
      <TableCell>
        <div class="btn-group gap-2">
          {/* View Element Event Donasi Table Element*/}
          {checkPermission('UpdateCOD') && (
            <Link to={`/confirmation/update?id=${donasiItem.id}`}>
              <Button variant="secondary">Update Status</Button>
            </Link>
          )}

          <Link to={`/confirmation/detail/${donasiItem.id}`}>
            <Button variant="tertiary">Detail</Button>
          </Link>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default DonasiTable
