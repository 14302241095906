import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  List,
} from 'commons/components'

import IncomeTable from '../components/IncomeTable'
import getIncomeListElement from '../services/getIncomeListElement'

const DaftarIncomePage = props => {
  const { checkPermission } = useAuth()
  const [income, setIncome] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const { data: incomeListElement } = await getIncomeListElement()
      setIncome(incomeListElement.data)
    }
    fetchData()
  }, [])

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0">Daftar Income</h2>
        <div className="not-prose flex items-center gap-4">
          {checkPermission('CreateIncome') && (
            <Link to="/income/tambah">
              <Button>Tambah Pemasukan</Button>
            </Link>
          )}
        </div>
      </div>
      <div className="not-prose">
        <TableView {...{ income }} />
      </div>
    </div>
  )
}

const TableView = ({ income }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell id="tanggal">Tanggal</TableCell>
              <TableCell id="program">Program</TableCell>
              <TableCell id="metodePembayaran">Metode Pembayaran</TableCell>
              <TableCell id="jumlah">Jumlah</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {income &&
              income.map(incomeItem => (
                <IncomeTable key={incomeItem.id} incomeItem={incomeItem} />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default DaftarIncomePage
