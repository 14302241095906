import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  List,
} from 'commons/components'

import ExpenseTable from '../components/ExpenseTable'
import getExpenseListElement from '../services/getExpenseListElement'

const CatatanPengeluaranPage = props => {
  const { checkPermission } = useAuth()
  const [expenses, setExpenses] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const { data: expenseListElement } = await getExpenseListElement()
      setExpenses(expenseListElement.data)
    }
    fetchData()
  }, [])

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0">Catatan Pengeluaran</h2>
        <div className="not-prose flex items-center gap-4">
          {checkPermission('CreateExpense') && (
            <Link to="/expense/tambah">
              <Button>Tambah Pengeluaran</Button>
            </Link>
          )}
        </div>
      </div>
      <div className="not-prose">
        <TableView {...{ expenses }} />
      </div>
    </div>
  )
}

const TableView = ({ expenses }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell id="tanggal">Tanggal</TableCell>
              <TableCell id="program">Program</TableCell>
              <TableCell id="kategori">Kategori</TableCell>
              <TableCell id="jumlah">Jumlah</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expenses &&
              expenses.map(expenseItem => (
                <ExpenseTable key={expenseItem.id} expenseItem={expenseItem} />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default CatatanPengeluaranPage
